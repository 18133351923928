.hood-red {fill:#E90924}
.hood-orange {fill:#FF6512}
.hood-yellow {fill:#FBEF12}
.hood-green {fill:#76F720}
.hood-tiffany {fill:#0BD4AC}
.hood-light-blue {fill:#169DE0}
.hood-blue {fill:#2C33C7}
.hood-brown {fill:#4F321F}
.hood-pupple {fill:#863CB5}
.hood-pink {fill:#F9CDDA}
.hood-white {fill:#FFFFFF}
.hood-gray {fill:#7D7D7D}
.hood-black {fill:#303030}
.hood-texture-black {fill:url(#Pattern)}
.hood-texture-1 {fill:url(#Pattern1)}
.hood-texture-2 {fill:url(#Pattern2)}
.hood-dark-green {fill:#205C50}
.hood-dark-blue {fill:#253856}
.hood-dark-gray {fill:#575757}
.hood-rose-red {fill:#E34496}
