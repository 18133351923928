.str_332C2B_75 {stroke:#332C2B;stroke-width:75}
.str_332C2B_50 {stroke:#332C2B;stroke-width:50}
.str_332C2B_20 {stroke:#332C2B;stroke-width:20}
.str_222222_8 {stroke:#222222;stroke-width:8}
.str_222222_75 {stroke:#222222;stroke-width:75}
.str_222222_50 {stroke:#222222;stroke-width:50}
.str_222222_30 {stroke:#222222;stroke-width:30}
.str_222222_35 {stroke:#222222;stroke-width:35}
.str_222222_25 {stroke:#222222;stroke-width:25}
.str_222222_25_125_75 {stroke:#222222;stroke-width:25;stroke-dasharray:125.000000 75.000000}
.str_222222_20_40_20 {stroke:#222222;stroke-width:20;stroke-dasharray:40.000000 20.000000}
.str_222222_20_100_60 {stroke:#222222;stroke-width:20;stroke-dasharray:100.000000 60.000000}
.str_222222_30_150_90 {stroke:#222222;stroke-width:30;stroke-dasharray:150.000000 90.000000}
.str_222222_30_150_150 {stroke:#222222;stroke-width:30;stroke-dasharray:150.000000 150.000000}
.str_222222_25_125_125 {stroke:#222222;stroke-width:25;stroke-dasharray:125.000000 125.000000}
.str_222222_50_250_150 {stroke:#222222;stroke-width:50;stroke-dasharray:250.000000 150.000000}
.str_332C2B_25_50_75 {stroke:#332C2B;stroke-width:25;stroke-dasharray:50.000000 75.000000}
.str_332C2B_20_40_60 {stroke:#332C2B;stroke-width:20;stroke-dasharray:40.000000 60.000000}

.clear {fill:none}
.fil-back {fill:black}
.fil-FEFEFE {fill:#FEFEFE}
.fil-7F7F7F {fill:#7F7F7F}

.str-button {stroke:#222222;stroke-width:7.62}
.fil-button-light {fill:#FEFEFE}

.fil-eye-border {fill:#212121}
.fil-eye {fill:#3D3D3D}

.fil-nose {fill:#585858}
